import React from 'react';
import { screenGrey4, screenGrey5, screenPrimary3, } from '../../style/colors.js';
import { Illustration } from '../Illustration.js';
export const AstronautIllustration = ({ title }) => (React.createElement(Illustration, { viewBox: "0 0 995.79 756.3" },
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "afc558c8-c8c4-43a0-9ff8-36c738bcadf8", x1: "263.72", y1: "238.68", x2: "263.72", y2: "208.45", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0", stopColor: screenGrey4, stopOpacity: "0.25" }),
            React.createElement("stop", { offset: "0.54", stopColor: screenGrey4, stopOpacity: "0.12" }),
            React.createElement("stop", { offset: "1", stopColor: screenGrey4, stopOpacity: "0.1" })),
        React.createElement("linearGradient", { id: "be82fdd5-66ab-49fc-8aef-9500b2c1a08e", x1: "537.72", y1: "134.68", x2: "537.72", y2: "104.45", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "b48d588d-308d-4a6b-9b06-bb920dd362b3", x1: "814.72", y1: "181.68", x2: "814.72", y2: "151.45", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "fc40df44-a3eb-403a-93a3-c2e14ff8183d", x1: "358.87", y1: "217.6", x2: "358.87", y2: "193.82", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "b9a74a8c-4f86-4b12-a01e-b38b12640f79", x1: "708.87", y1: "160.6", x2: "708.87", y2: "136.82", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "e1cc7b1e-4a38-4d88-bc31-d957dc0e789b", x1: "909.87", y1: "160.6", x2: "909.87", y2: "136.82", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "a786e464-0004-4162-bf9c-1c77ea556f3f", x1: "343.18", y1: "57.12", x2: "343.18", y2: "44.98", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "ed7113af-a3f6-4b83-ad2a-4145c0897ac0", x1: "344.18", y1: "133.12", x2: "344.18", y2: "120.98", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "bcce56f4-6100-452c-94c0-0e4dd07d63d7", x1: "663.18", y1: "41.12", x2: "663.18", y2: "28.98", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "f80e6619-6a6a-4d27-b58b-13c487a3d966", x1: "802.18", y1: "149.12", x2: "802.18", y2: "136.98", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "f9495c88-7725-4ca5-9368-72e54c981d92", x1: "490.73", y1: "580.9", x2: "490.73", y2: "313.1", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "b82d2768-795c-4dc2-9de8-934b40644624", x1: "592.83", y1: "800.34", x2: "592.83", y2: "416.27", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "e991d392-04f4-4018-b9d8-24ee41179e03", x1: "593.61", y1: "431.93", x2: "593.61", y2: "298.81", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "f4033a33-768d-491b-9f07-cdde246aef8f", x1: "551.03", y1: "816.05", x2: "551.03", y2: "767.07", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "bd26dae2-ae14-4e05-844d-7f17be67c7f9", x1: "635.59", y1: "816.05", x2: "635.59", y2: "767.07", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "a2f70ec8-8dd0-4c65-80e1-e3da54a3f4f7", x1: "745.34", y1: "828.15", x2: "745.34", y2: "252.93", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "e5b0ae8b-f5f0-41eb-bef6-53438e13ac16", x1: "924.91", y1: "439.7", x2: "924.91", y2: "234.67", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "f0273ade-576a-4ad8-9a6f-7fc7971c2987", x1: "436.34", y1: "828.15", x2: "436.34", y2: "252.93", gradientTransform: "matrix(-1, 0, 0, 1, 891, 0)", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "e88a7565-ba81-4ab7-95f1-6629f0af5c08", x1: "615.91", y1: "439.7", x2: "615.91", y2: "234.67", gradientTransform: "matrix(-1, 0, 0, 1, 891, 0)", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "bf514d7a-d100-48d8-89e1-e526b58397db", x1: "492.29", y1: "513.56", x2: "492.29", y2: "403.93", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "a76115cf-cd8c-4176-9a3c-9a73b10c4903", x1: "798.43", y1: "18.6", x2: "798.43", y2: "0", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "a32a47df-12b4-443d-9765-8cd8524a5228", x1: "765.43", y1: "143.6", x2: "765.43", y2: "125", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "be475b5a-0363-4725-9e86-39c6835f7bb0", x1: "630.43", y1: "160.6", x2: "630.43", y2: "142", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "ba4f5741-eaf0-4ada-8124-dbf7ca11ac85", x1: "545.43", y1: "61.6", x2: "545.43", y2: "43", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "ed21139e-2a95-41cd-b755-047a620bfa49", x1: "436.43", y1: "115.6", x2: "436.43", y2: "97", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "e90eae1c-1fda-4179-aacd-c7bc40fa73b9", x1: "247.43", y1: "75.6", x2: "247.43", y2: "57", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "abb092bc-1b17-4de6-b635-8e2be4eed0bd", x1: "214.43", y1: "234.6", x2: "214.43", y2: "216", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "e8540004-522d-4b52-801f-b41ff9acf3f2", x1: "736.95", y1: "40.66", x2: "736.95", y2: "30.61", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "a5f9757d-6ad9-4e45-a734-ac7d7f2fecf7", x1: "185.95", y1: "97.66", x2: "185.95", y2: "87.61", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "a1b47319-948d-4901-bb26-91f5cee31525", x1: "250.95", y1: "224.66", x2: "250.95", y2: "214.61", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "b1659724-cb8a-4fa8-b312-9f444de5ed18", x1: "364.95", y1: "207.66", x2: "364.95", y2: "197.61", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" }),
        React.createElement("linearGradient", { id: "ec4cfa8f-50ab-441a-b17a-cb383eba365e", x1: "529.95", y1: "128.66", x2: "529.95", y2: "118.61", xlinkHref: "#afc558c8-c8c4-43a0-9ff8-36c738bcadf8" })),
    React.createElement("title", null, title),
    React.createElement("rect", { x: "411.64", y: "313.1", width: "158.17", height: "267.8", rx: "13", fill: "url(#f9495c88-7725-4ca5-9368-72e54c981d92)" }),
    React.createElement("rect", { x: "416.17", y: "318.74", width: "150.17", height: "255.21", rx: "13", fill: screenPrimary3 }),
    React.createElement("polyline", { points: "416.17 664.36 482.49 664.36 466.18 730.97", fill: "none" }),
    React.createElement("polygon", { points: "536.72 380.06 450.94 381.83 427.19 354.54 555.07 352.24 536.72 380.06", fill: screenGrey5 }),
    React.createElement("polygon", { points: "555.07 354.37 427.19 354.37 452.71 340.72 527.86 338.24 555.07 354.37", fill: screenGrey5 }),
    React.createElement("path", { d: "M751.58,462.48c-12.09-5.36-28.3,2-31.74,14.37-9.09-25.88-31.1-47-57.54-56.54-4.37-1.58-16.5-6.39-19.86-2.64-1.56,1.74-4.57,8.61-4.38,14.34H548.13c0-1.12,0-2.24,0-3.36,0-13.07-10.51-13.52-23.21-10.47a70.4,70.4,0,0,0-29.63,15.92c-13.51,11.91-22.9,27.45-32.08,42.72l-16-15.89c-1.76-1.75-3.86-3.63-6.39-3.51-2.68.13-4.62,2.44-6.18,4.54-7.06,9.52-14.34,19.53-16,31.12-1.66,11.41,2.31,22.81,6.23,33.69,3.82,10.64,8.9,25.68,19.89,29.47,11.83,4.09,24.31,4.57,34.55-2.44,16-11,32.49-22.37,44.17-37.42V630.64h1.35l-11,169.7,73.54-.38,5.25-169.32h2.16L598.31,800l74.82.38-11.21-169.7h2.32V515.24l2,1.66,36.29,30.35c7.55,6.32,16.19,13,26.17,12.51,7.17-.36,13.67-12.38,18.25-17.71s7.55-11.75,10.46-18.09c4.82-10.49,9.73-21.33,10.06-32.8S762.4,467.27,751.58,462.48Z", transform: "translate(-102.11 -71.85)", fill: "url(#b82d2768-795c-4dc2-9de8-934b40644624)" }),
    React.createElement("path", { d: "M744.7,465.12c-11.57-5.31-27.07,2-30.36,14.27-8.7-25.69-29.75-46.61-55-56.1-4.19-1.57-14.29-6.34-17.5-2.63-1.5,1.73-1.38,8.55-1.2,14.24h-95c0-1.12,0-2.23,0-3.34-.05-13-5.57-13.41-17.72-10.38-10.59,2.64-20.33,8.46-28.34,15.79-12.93,11.82-21.91,27.23-30.68,42.39l-15.32-15.77c-1.69-1.73-3.7-3.61-6.12-3.49s-4.41,2.42-5.91,4.51c-6.75,9.45-13.71,19.38-15.33,30.88-1.59,11.32,2.21,22.63,5.95,33.43,3.66,10.55,8.52,22.36,19,26.13,11.31,4.05,23.25-3.24,33-10.19,15.34-10.88,31.07-22.2,42.25-37.13V632h1.29l-10.5,168.37h67.34l8-168.37h2.06l6.22,168.37h68.76L658.94,632h2.21V509.71l1.89,1.64,34.71,30.11c7.22,6.27,15.49,12.92,25,12.42,6.87-.36,13.08-4.52,17.47-9.81s7.22-11.66,10-17.94c4.61-10.41,9.31-21.16,9.62-32.55S755.05,469.88,744.7,465.12Z", transform: "translate(-102.11 -71.85)", fill: "#fff" }),
    React.createElement("path", { d: "M668.79,348.62H655.31c-7.93-29.27-32.62-49.81-61.87-49.81s-53.93,20.54-61.86,49.81H518.44V379h11.24c4.84,31.84,31.54,52.94,63.76,52.94s58.93-21.1,63.77-52.94h11.58Z", transform: "translate(-102.11 -71.85)", fill: "url(#e991d392-04f4-4018-b9d8-24ee41179e03)" }),
    React.createElement("path", { d: "M665,350.54h-12.8c-7.53-26.66-31-48.21-58.77-48.21s-51.23,21.55-58.77,48.21H522.21v27.64h10.67c4.6,29,30,48.21,60.57,48.21s56-19.22,60.58-48.21h11Z", transform: "translate(-102.11 -71.85)", fill: "#fff" }),
    React.createElement("circle", { cx: "490.99", cy: "297.12", r: "49.62", fill: screenPrimary3 }),
    React.createElement("path", { d: "M588.92,802.13c0,16.57-17,13.73-37.89,13.73s-37.89,2.84-37.89-13.73,17-35.06,37.89-35.06S588.92,785.56,588.92,802.13Z", transform: "translate(-102.11 -71.85)", fill: "url(#f4033a33-768d-491b-9f07-cdde246aef8f)" }),
    React.createElement("path", { d: "M673.48,802.13c0,16.57-17,13.73-37.89,13.73s-37.89,2.84-37.89-13.73,17-35.06,37.89-35.06S673.48,785.56,673.48,802.13Z", transform: "translate(-102.11 -71.85)", fill: "url(#bd26dae2-ae14-4e05-844d-7f17be67c7f9)" }),
    React.createElement("path", { d: "M586.11,801.23c0,15.17-15.71,12.58-35.08,12.58s-35.09,2.59-35.09-12.58,15.71-32.08,35.09-32.08S586.11,786.06,586.11,801.23Z", transform: "translate(-102.11 -71.85)", fill: screenGrey4 }),
    React.createElement("path", { d: "M671.18,801.23c0,15.17-15.71,12.57-35.08,12.57S601,816.53,601,801.36s15.71-32.09,35.09-32.08S671.18,786.06,671.18,801.23Z", transform: "translate(-102.11 -71.85)", fill: screenGrey4 }),
    React.createElement("path", { d: "M758.05,265.64a12.72,12.72,0,1,0-21.92,8.76V823.6a4.56,4.56,0,0,0,4.55,4.55h8.13a4.57,4.57,0,0,0,4.55-4.55V275.51A12.69,12.69,0,0,0,758.05,265.64Z", transform: "translate(-102.11 -71.85)", fill: "url(#a2f70ec8-8dd0-4c65-80e1-e3da54a3f4f7)" }),
    React.createElement("path", { d: "M756.5,265.64a11.17,11.17,0,1,0-17,9.5V821A4.55,4.55,0,0,0,744,825.5h3.25a4.56,4.56,0,0,0,4.55-4.54V274.71A11.15,11.15,0,0,0,756.5,265.64Z", transform: "translate(-102.11 -71.85)", fill: screenGrey5 }),
    React.createElement("path", { d: "M738.29,472.72c-2,3.09-6,5.66-9.33,4-2.68-1.33-3.54-4.69-3.88-7.66a61.27,61.27,0,0,1,.95-19.94c.86-4,2.69-8.47,6.62-9.52,2.46-.65,5.07.28,7.23,1.63s4,3.1,6.23,4.37c4.24,2.44,9.4,2.88,14.24,2.17,2.4-.35,4.76-1,7.17-1.17a11.94,11.94,0,0,1,7.05,1.23,6.37,6.37,0,0,1,3.32,6c-.3,2.41-2.84,4.45-5.17,3.77a14.85,14.85,0,0,1,5.18,4.71,6.73,6.73,0,0,1,.38,6.71c-1.2,2-4.16,3-6.07,1.61,1.95,1.6,4,3.3,4.88,5.65s.17,5.53-2.18,6.45a20.37,20.37,0,0,1-3,.56c-1,.24-2,.92-2.13,1.95-.09,1.19,1.1,2,1.8,3,1.57,2.12.7,5.31-1.19,7.14s-4.52,2.65-7,3.41c-3.76,1.13-7.52,2.26-11.3,3.32-4.14,1.16-8.48,2.24-12.68,1.34a13.86,13.86,0,0,1-5.16-24.83c2.07-1.48,2.45-4,4.12-5.89a12.78,12.78,0,0,0,3.05-8.77c0-4.72,1.8-9.28.7-13.87-.26,0-.45.05-.71.1C741.14,458.07,743,465.57,738.29,472.72Z", transform: "translate(-102.11 -71.85)", fill: screenPrimary3 }),
    React.createElement("path", { d: "M1097.89,402.18c-115.32-130-230.64,130-346,0v-130c115.32,130,230.64-130,346,0Z", transform: "translate(-102.11 -71.85)", fill: "url(#e5b0ae8b-f5f0-41eb-bef6-53438e13ac16)" }),
    React.createElement("path", { d: "M1094.18,397.32C980.1,278.94,866,515.71,751.93,397.32V278.94c114.08,118.38,228.17-118.39,342.25,0Z", transform: "translate(-102.11 -71.85)", fill: screenPrimary3 }),
    React.createElement("path", { d: "M442,265.64a12.72,12.72,0,1,1,21.92,8.76V823.6a4.56,4.56,0,0,1-4.55,4.55h-8.13a4.57,4.57,0,0,1-4.55-4.55V275.51A12.69,12.69,0,0,1,442,265.64Z", transform: "translate(-102.11 -71.85)", fill: "url(#f0273ade-576a-4ad8-9a6f-7fc7971c2987)" }),
    React.createElement("path", { d: "M443.5,265.64a11.17,11.17,0,1,1,17,9.5V821A4.55,4.55,0,0,1,456,825.5h-3.25a4.56,4.56,0,0,1-4.55-4.54V274.71A11.15,11.15,0,0,1,443.5,265.64Z", transform: "translate(-102.11 -71.85)", fill: screenGrey5 }),
    React.createElement("path", { d: "M102.11,402.18c115.32,130,230.64-130,346,0v-130c-115.32-130-230.64,130-346,0Z", transform: "translate(-102.11 -71.85)", fill: "url(#e88a7565-ba81-4ab7-95f1-6629f0af5c08)" }),
    React.createElement("path", { d: "M105.82,397.32c114.08,118.39,228.17-118.38,342.25,0V278.94C334,160.55,219.9,397.32,105.82,278.94Z", transform: "translate(-102.11 -71.85)", fill: screenPrimary3 }),
    React.createElement("rect", { x: "441.39", y: "403.93", width: "101.8", height: "109.63", rx: "6.38", fill: "url(#bf514d7a-d100-48d8-89e1-e526b58397db)" }),
    React.createElement("rect", { x: "443.48", y: "407.32", width: "97.13", height: "102.82", rx: "6.38", fill: screenPrimary3 }),
    React.createElement("rect", { x: "456.08", y: "426.88", width: "17.8", height: "47.46", opacity: "0.2" }),
    React.createElement("rect", { x: "494.72", y: "444.2", width: "23.41", height: "16.02", opacity: "0.2" }),
    React.createElement("rect", { x: "511.73", y: "418.88", width: "12.94", height: "11.51", opacity: "0.2" }),
    React.createElement("circle", { cx: "519.08", cy: "482.23", r: "14.09", opacity: "0.2" }),
    React.createElement("path", { d: "M599.36,330.31A49.55,49.55,0,0,1,639,350.05a49.63,49.63,0,1,0-85.5,48.8,49.64,49.64,0,0,1,45.88-68.54Z", transform: "translate(-102.11 -71.85)", opacity: "0.2" }),
    React.createElement("path", { d: "M467.71,472.72c2.05,3.09,6,5.66,9.33,4,2.68-1.33,3.54-4.69,3.88-7.66a61.27,61.27,0,0,0-.95-19.94c-.86-4-2.69-8.47-6.62-9.52-2.46-.65-5.07.28-7.23,1.63s-4,3.1-6.23,4.37c-4.24,2.44-9.4,2.88-14.24,2.17-2.4-.35-4.76-1-7.17-1.17a11.94,11.94,0,0,0-7.05,1.23,6.37,6.37,0,0,0-3.32,6c.3,2.41,2.84,4.45,5.17,3.77a14.85,14.85,0,0,0-5.18,4.71,6.73,6.73,0,0,0-.38,6.71c1.2,2,4.16,3,6.07,1.61-2,1.6-4,3.3-4.88,5.65s-.17,5.53,2.18,6.45a20.37,20.37,0,0,0,3,.56c1,.24,2.05.92,2.13,1.95.09,1.19-1.1,2-1.8,3-1.57,2.12-.7,5.31,1.19,7.14s4.52,2.65,7,3.41c3.76,1.13,7.52,2.26,11.3,3.32,4.14,1.16,8.48,2.24,12.68,1.34a13.86,13.86,0,0,0,5.16-24.83c-2.07-1.48-2.45-4-4.12-5.89a12.78,12.78,0,0,1-3-8.77c0-4.72-1.8-9.28-.7-13.87.26,0,.45.05.71.1C464.86,458.07,463,465.57,467.71,472.72Z", transform: "translate(-102.11 -71.85)", fill: screenPrimary3 })));
